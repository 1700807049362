import tokens from '../tokens'
import { PoolCategory, PoolConfig } from '../types'

export const bnbPools: PoolConfig[] = [
  // {
  //   chainId: 56,
  //   sousId: 56001,
  //   stakingToken: tokens.rba,
  //   earningToken: tokens.rba,
  //   contractAddress: {
  //     97: '0x1B6C17ab5347F9085222a6866691DE221f05372E',
  //     56: '0x1B6C17ab5347F9085222a6866691DE221f05372E',
  //   },
  //   depositAddress: {
  //     97: '0x13200e54d7c97FD4879fBeFfA4d1b1344568615b',
  //     56: '0x13200e54d7c97FD4879fBeFfA4d1b1344568615b',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   isLock: false,
  //   lockTime: 365,
  //   harvest: true,

  //   rate: 2,
  //   tokenPerBlock: '10',
  //   sortOrder: 3,
  //   isFinished: true,
  //   isDisabled: true,
  //   isMembership: false,
  //   isCustomReward: false,
  //   customRewardAddress: {
  //     97: '0x1B6C17ab5347F9085222a6866691DE221f05372E',
  //     56: '0x1B6C17ab5347F9085222a6866691DE221f05372E',
  //   },
  // },
  // {
  //   chainId: 56,
  //   sousId: 56002,
  //   stakingToken: tokens.rba,
  //   earningToken: tokens.rba,
  //   contractAddress: {
  //     97: '0xA7b4a92C0B00d7344c9df29F412cbA2319875884',
  //     56: '0xA7b4a92C0B00d7344c9df29F412cbA2319875884',
  //   },
  //   depositAddress: {
  //     97: '0xF0EC83ea8F59F61Fd18121300D729D493B58d9De',
  //     56: '0xF0EC83ea8F59F61Fd18121300D729D493B58d9De',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   isLock: true,
  //   lockTime: 365,
  //   harvest: true,
  //   lockInfo: 'Unlocks on mainnet',
  //   rate: 8,
  //   tokenPerBlock: '10',
  //   sortOrder: 3,
  //   isFinished: true,
  //   isDisabled: true,
  //   isMembership: false,
  //   isCustomReward: true,
  //   customRewardAddress: {
  //     56: '0x8AB3D24862eB222D318065a5f6988aF5F018628D',
  //     97: '0x8AB3D24862eB222D318065a5f6988aF5F018628D',
  //   },
  // },
  // {
  //   chainId: 56,
  //   sousId: 56003,
  //   stakingToken: tokens.rba,
  //   earningToken: tokens.rba,
  //   contractAddress: {
  //     97: '0xC7c94f756B95F6F392F07392076b85f865c39A36',
  //     56: '0xC7c94f756B95F6F392F07392076b85f865c39A36',
  //   },
  //   depositAddress: {
  //     97: '0xc1fa268d10544BC93D760ce9C67dA3912D7694DC',
  //     56: '0xc1fa268d10544BC93D760ce9C67dA3912D7694DC',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   isLock: true,
  //   lockTime: 730,
  //   harvest: true,
  //   rate: 12,
  //   tokenPerBlock: '10',
  //   sortOrder: 3,
  //   isFinished: true,
  //   isDisabled: true,
  //   isMembership: false,
  //   isCustomReward: true,
  //   customRewardAddress: {
  //     56: '0x5ce09D4428137ca813CCa24C4E2CDBfe9615DC9B',
  //     97: '0x5ce09D4428137ca813CCa24C4E2CDBfe9615DC9B',
  //   },
  // },

  // {
  //   chainId: 56,
  //   sousId: 56004,
  //   stakingToken: tokens.rba,
  //   earningToken: tokens.rba,
  //   contractAddress: {
  //     97: '0x1B6C17ab5347F9085222a6866691DE221f05372E',
  //     56: '0x1a88a6D94d725c75BA868735F1a750774b3aD3c2',
  //   },
  //   depositAddress: {
  //     97: '0x13200e54d7c97FD4879fBeFfA4d1b1344568615b',
  //     56: '0xecC8c82b05DE9708561902397E236c81fd739cEF',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   isLock: false,
  //   lockTime: 365,
  //   harvest: true,
  //   rate: 2,
  //   tokenPerBlock: '10',
  //   sortOrder: 3,
  //   isFinished: false,
  //   isDisabled: false,
  //   isMembership: false,
  //   isCustomReward: false,
  //   customRewardAddress: {
  //     97: '0x1B6C17ab5347F9085222a6866691DE221f05372E',
  //     56: '0x1a88a6D94d725c75BA868735F1a750774b3aD3c2',
  //   },
  // },
  // {
  //   chainId: 56,
  //   sousId: 56005,
  //   stakingToken: tokens.rba,
  //   earningToken: tokens.rba,
  //   contractAddress: {
  //     97: '0x1B6C17ab5347F9085222a6866691DE221f05372E',
  //     56: '0x102CFb2fa87781F579A7E3C27B039b105Cedf1ee',
  //   },
  //   depositAddress: {
  //     97: '0x13200e54d7c97FD4879fBeFfA4d1b1344568615b',
  //     56: '0xbF301687B06deA6094d7B3D462E572381539872c',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   isLock: true,
  //   lockTime: 1095,
  //   harvest: true,
  //   rate: 5,
  //   tokenPerBlock: '10',
  //   sortOrder: 3,
  //   isFinished: false,
  //   isDisabled: false,
  //   isMembership: false,
  //   isCustomReward: false,
  //   customRewardAddress: {
  //     97: '0x1B6C17ab5347F9085222a6866691DE221f05372E',
  //     56: '0x102CFb2fa87781F579A7E3C27B039b105Cedf1ee',
  //   },
  // },
  // {
  //   chainId: 56,
  //   sousId: 56006,
  //   stakingToken: tokens.rba,
  //   earningToken: tokens.rba,
  //   contractAddress: {
  //     97: '0x1B6C17ab5347F9085222a6866691DE221f05372E',
  //     56: '0x4ef29a5Ea779B498c9a9A6911890dd21fF53a2Fd',
  //   },
  //   depositAddress: {
  //     97: '0x13200e54d7c97FD4879fBeFfA4d1b1344568615b',
  //     56: '0xfBfcE6D74D7ac4eF93f2F5522d7712B4F0c95E17',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   isLock: true,
  //   lockTime: 1825,
  //   harvest: true,
  //   rate: 8,
  //   tokenPerBlock: '10',
  //   sortOrder: 3,
  //   isFinished: false,
  //   isDisabled: false,
  //   isMembership: false,
  //   isCustomReward: false,
  //   customRewardAddress: {
  //     97: '0x1B6C17ab5347F9085222a6866691DE221f05372E',
  //     56: '0x4ef29a5Ea779B498c9a9A6911890dd21fF53a2Fd',
  //   },
  // },
  {
    chainId: 56,
    sousId: 7,
    stakingToken: tokens.TTN,
    earningToken: tokens.TTN,
    // contractAddress: {
    //   97: '0xfDD1977EE2B1ae19D907FfD13277610870B209Ed',
    //   56: '0xfDD1977EE2B1ae19D907FfD13277610870B209Ed',
    // },
    depositAddress: {
      97: '0xCC2542b84bB6168bC4D54768C9AA33b5728686AE',
      56: '0xCC2542b84bB6168bC4D54768C9AA33b5728686AE',
    },
    contractAddress: {
      97: '0xCC2542b84bB6168bC4D54768C9AA33b5728686AE',
      56: '0xCC2542b84bB6168bC4D54768C9AA33b5728686AE',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    isLock: false,
    lockTime: 365,
    rate: 10, // 10%
    tokenPerBlock: '10', // not important
    sortOrder: 0,
    isFinished: true,
    isMembership: false,
    isCustomReward: false,
    customRewardAddress: {
      97: '0xCC2542b84bB6168bC4D54768C9AA33b5728686AE',
      56: '0xCC2542b84bB6168bC4D54768C9AA33b5728686AE',
    },
  },

  // mangaai
  {
    chainId: 56,
    sousId: 11,
    stakingToken: tokens.mangaai,
    earningToken: tokens.mangaai,
    contractAddress: {
      97: '0x8f6fb2fE5ce98aCaC253fc006431436031da480C',
      56: '0xC7969e56fA827edC07b6a3E251650E6d3f2EDbCC',
    },
    depositAddress: {
      97: '0xc31Fe3840566A2800dd699dd0225c82DB16778EE',
      56: '0xDeB98A10Ac02903a891663F5c8c16a37A0a98C28',
    },
    poolCategory: PoolCategory.CORE,
    isLock: false,
    lockTime: 1825,
    harvest: true,
    rate: 2,
    tokenPerBlock: '10',
    sortOrder: 3,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
    customRewardAddress: {
      97: '0x8f6fb2fE5ce98aCaC253fc006431436031da480C',
      56: '0xC7969e56fA827edC07b6a3E251650E6d3f2EDbCC',
    },
  },

  {
    chainId: 56,
    sousId: 12,
    stakingToken: tokens.mangaai,
    earningToken: tokens.mangaai,
    contractAddress: {
      97: '0x94A2f402d4cfD2d8d5be3038FEBb02a8D9803Bb9',
      56: '0x45069e7De24Df27e67a165Dd48Eb7A937ff4dC26',
    },
    depositAddress: {
      97: '0xE809fa365A699E290d1336031431704f9547b9B1',
      56: '0xDeB98A10Ac02903a891663F5c8c16a37A0a98C28',
    },
    poolCategory: PoolCategory.CORE,
    isLock: true,
    lockTime: 30,
    harvest: true,
    rate: 4,
    tokenPerBlock: '10',
    sortOrder: 3,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
    customRewardAddress: {
      97: '0x94A2f402d4cfD2d8d5be3038FEBb02a8D9803Bb9',
      56: '0x45069e7De24Df27e67a165Dd48Eb7A937ff4dC26',
    },
  },

  {
    chainId: 56,
    sousId: 13,
    stakingToken: tokens.mangaai,
    earningToken: tokens.mangaai,
    contractAddress: {
      97: '0x06cF875Ea81C8da6Efcb450BB58901126eD18ae9',
      56: '0x9Ef01ee26fE89B48e0A470d0dbd473eB71BC2769',
    },
    depositAddress: {
      97: '0xB381c5a18957cd32956FCe730aeD248E9971A08E',
      56: '0x3e1e5369e37114C060d933773fEc8ba0d9e7Bad9',
    },
    poolCategory: PoolCategory.CORE,
    isLock: true,
    lockTime: 180,
    harvest: true,
    rate: 6,
    tokenPerBlock: '10',
    sortOrder: 3,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
    customRewardAddress: {
      97: '0x06cF875Ea81C8da6Efcb450BB58901126eD18ae9',
      56: '0x9Ef01ee26fE89B48e0A470d0dbd473eB71BC2769',
    },
  },
  // mangaai membership

  {
    chainId: 56,
    sousId: 14,
    stakingToken: tokens.mangaai,
    earningToken: tokens.mangaai,
    contractAddress: {
      97: '0xaF2c078bC3F497520AB289F0D161B6cc351ab529',
      56: '0xd1Eb809f3cF7b446BBFEc50b01224bB65d150C03',
    },
    depositAddress: {
      97: '0xAC5C6501CfC4D1dfe543E3f73714ACE5EF9BDd46',
      56: '0x48eefeD680d8ec28156F644c988460e8E83Ab9DA',
    },
    poolCategory: PoolCategory.CORE,
    isLock: false,
    lockTime: 180,
    harvest: true,
    rate: 3,
    tokenPerBlock: '10',
    sortOrder: 3,
    isFinished: false,
    isDisabled: false,
    isMembership: true,
    membershipAddress: {
      56: '0x26B4C58DD868CC3Fb6aD27292A9509Af5ce5B9Ca',
      97: '0x9655B935A84E2E99370a0c4eC6eF42387A2eaC18',
    },
    isCustomReward: false,
    customRewardAddress: {
      97: '0xaF2c078bC3F497520AB289F0D161B6cc351ab529',
      56: '0xd1Eb809f3cF7b446BBFEc50b01224bB65d150C03',
    },
  },

  {
    chainId: 56,
    sousId: 15,
    stakingToken: tokens.mangaai,
    earningToken: tokens.mangaai,
    contractAddress: {
      97: '0xcc0b7a70e376AaEbE9c2500064977a325e7dD9e9',
      56: '0xf7fb8f49d47e8BeC9fA167a4d80C9f5a4C4C3322',
    },
    depositAddress: {
      97: '0x92B16Ab01B35FDB9E5df3Fe1f6029cD3D2AE49D8',
      56: '0x23e46B164812dd6eC0eee4321a83BB528866Fc11',
    },
    poolCategory: PoolCategory.CORE,
    isLock: true,
    lockTime: 30,
    harvest: true,
    rate: 6,
    tokenPerBlock: '10',
    sortOrder: 3,
    isFinished: false,
    isDisabled: false,
    isMembership: true,
    membershipAddress: {
      56: '0x26B4C58DD868CC3Fb6aD27292A9509Af5ce5B9Ca',
      97: '0x9655B935A84E2E99370a0c4eC6eF42387A2eaC18',
    },
    isCustomReward: false,
    customRewardAddress: {
      97: '0xcc0b7a70e376AaEbE9c2500064977a325e7dD9e9',
      56: '0xf7fb8f49d47e8BeC9fA167a4d80C9f5a4C4C3322',
    },
  },

  {
    chainId: 56,
    sousId: 16,
    stakingToken: tokens.mangaai,
    earningToken: tokens.mangaai,
    contractAddress: {
      97: '0xa39b756C2a68054Aa88BCB46914BE06f6748Ef75',
      56: '0xa49f4E30d25F59e757C497191c698B512b0Cfef3',
    },
    depositAddress: {
      97: '0x12cB498cE9Fe54413774f8638EC5cB262aDA1804',
      56: '0x89E5167006aA8704F40a220bC6f4ea20703972c3',
    },
    poolCategory: PoolCategory.CORE,
    isLock: true,
    lockTime: 180,
    harvest: true,
    rate: 8,
    tokenPerBlock: '10',
    sortOrder: 3,
    isFinished: false,
    isDisabled: false,
    isMembership: true,
    membershipAddress: {
      56: '0x26B4C58DD868CC3Fb6aD27292A9509Af5ce5B9Ca',
      97: '0x9655B935A84E2E99370a0c4eC6eF42387A2eaC18',
    },
    isCustomReward: false,
    customRewardAddress: {
      97: '0xa39b756C2a68054Aa88BCB46914BE06f6748Ef75',
      56: '0xa49f4E30d25F59e757C497191c698B512b0Cfef3',
    },
  },
]

export const bnbTestnetPools: PoolConfig[] = []

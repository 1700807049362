import { Interface } from '@ethersproject/abi'
import multicallABI from 'config/abi/Multicall.json'
import { ethers } from 'ethers'
import { getMulticallAddress } from './addressHelpers'
import getProvider from './getProvider'

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (exemple: balanceOf)
  params?: any[] // Function params
}

const multicall = async (chainId: number, abi: any[], calls: Call[]) => {
  const multicallAddress = getMulticallAddress(chainId)
  const provider = getProvider(chainId)
  const multi = new ethers.Contract(multicallAddress, multicallABI, provider)
  const itf = new Interface(abi)
  // console.log('calls', calls)
  const validCalls = calls.filter((c) => c.address !== null && c.address !== undefined && c.address !== '')
  // console.log('validCalls', validCalls)
  const calldata = validCalls.map((call) => [
    call.address.toLowerCase(),
    itf.encodeFunctionData(call.name, call.params),
  ])
  try {
    const { returnData } = await multi.aggregate(calldata)
    const res = returnData.map((call, i) => itf.decodeFunctionResult(calls[i].name, call))
    return res
  } catch (error) {
    // console.log(validCalls.map((call) => `${call.name}, ${call.params}, ${call.address}`))
  }
  return []
}

export default multicall

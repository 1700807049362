import React from 'react'
import styled from 'styled-components'

import Logo from './Logo'

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
`

export default function CustomLogo({
  image,
  size = '24px',
  style,
}: {
  image?: string
  size?: string
  style?: React.CSSProperties
}) {
  return <StyledLogo size={size} srcs={[image]} alt="token logo" style={style} />
}

import { useEffect, useState } from 'react'

/* eslint-disable camelcase */
export interface PriceApiResponse {
  rbaPriceBnb: number
  rbaPriceUsd: number
  bnbPrice: number
}

export const useRBAPrice = () => {
  const [data, setData] = useState<PriceApiResponse | null>(null)

  useEffect(() => {
    let isMounted = true // Flag to track component mount state

    const fetchData = async () => {
      try {
        const response = await fetch('https://arbor-api.edscomp.com/price')
        const responseData: PriceApiResponse = await response.json()

        if (isMounted) {
          setData(responseData)
        }
      } catch (error) {
        console.error('Unable to fetch data:', error)
      }
    }

    fetchData()

    // Cleanup function to cancel ongoing operations
    return () => {
      isMounted = false // Set flag to indicate component unmount
    }
  }, []) // No dependencies here as we only want to fetch once

  return data
}

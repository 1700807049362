import React from 'react'
import styled from 'styled-components'
import Card from 'components/Card'

export const BodyWrapper = styled(Card)`
  max-width: 536px;
  width: 100%;
  z-index: 1;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppPortalBody({ children }: { children: React.ReactNode }) {
  return <BodyWrapper>{children}</BodyWrapper>
}

import { ChainId, Currency, ETHER, Token } from '@arborswap/sdk'
import addresses from 'config/constants/contracts'

export const getNativeName = (chainId: number) => {
  switch (chainId) {
    case ChainId.ETHEREUM:
      return 'ETH'
    case ChainId.BSC:
      return 'BNB'
    case ChainId.BSC_TESTNET:
      return 'BNB'
    case ChainId.ROBURNA:
    case ChainId.ROBURNA_TESTNET:
      return 'RBA'
    default:
      return 'RBA'
  }
}

export const getNativeSymbol = (chainId: number) => {
  switch (chainId) {
    case ChainId.ETHEREUM:
      return 'ETH'
    case ChainId.BSC:
      return 'BNB'
    case ChainId.BSC_TESTNET:
      return 'BNB'
    case ChainId.ROBURNA:
    case ChainId.ROBURNA_TESTNET:
      return 'RBA'
    default:
      return 'RBA'
  }
}

export const getWrappedNativeAddress = (chainId: number) => {
  return addresses.nativeWrapped[chainId]
}

export const getCurrencyName = (currency: Currency, chainId: number): string => {
  if (currency instanceof Token) {
    return currency.name
  }

  if (currency === ETHER) {
    return currency.getName(chainId)
  }

  return currency.name
}

export const getCurrencySymbol = (currency: Currency, chainId: number): string => {
  if (currency instanceof Token) {
    return currency.symbol
  }

  if (currency === ETHER) {
    return currency.getSymbol(chainId)
  }

  return ''
}

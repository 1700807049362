import { CHAIN_ID, NETWORK_ICON, NETWORK_LABEL } from 'config/constants/chains'

export const CHAIN_MENU_ITEM = [
  {
    iconimg: NETWORK_ICON[CHAIN_ID.ETHEREUM],
    chainId: CHAIN_ID.ETHEREUM,
    title: NETWORK_LABEL[CHAIN_ID.ETHEREUM],
  },
  {
    iconimg: NETWORK_ICON[CHAIN_ID.BSC],
    chainId: CHAIN_ID.BSC,
    title: NETWORK_LABEL[CHAIN_ID.BSC],
  },
  {
    iconimg: NETWORK_ICON[CHAIN_ID.BSC_TESTNET],
    chainId: CHAIN_ID.BSC_TESTNET,
    title: NETWORK_LABEL[CHAIN_ID.BSC_TESTNET],
  },
  {
    iconimg: NETWORK_ICON[CHAIN_ID.ROBURNA],
    chainId: CHAIN_ID.ROBURNA,
    title: NETWORK_LABEL[CHAIN_ID.ROBURNA],
  },
  {
    iconimg: NETWORK_ICON[CHAIN_ID.ROBURNA_TESTNET],
    chainId: CHAIN_ID.ROBURNA_TESTNET,
    title: NETWORK_LABEL[CHAIN_ID.ROBURNA_TESTNET],
  },
]

import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { updateUserStakedBalance, updateUserBalance } from 'state/actions'
import BigNumber from 'bignumber.js'
import { DEFAULT_GAS_LIMIT_STAKE } from 'config'
import { BIG_TEN } from 'utils/bigNumber'
import { useSousChef } from 'hooks/useContract'
import getGasPrice from 'utils/getGasPrice'
import { getPoolById } from 'utils/getPool'

const options = {
  gasLimit: DEFAULT_GAS_LIMIT_STAKE,
}

const sousStake = async (sousId, sousChefContract, amount, decimals = 18) => {
  const gasPrice = await getGasPrice()
  const tx = await sousChefContract.stake(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString(), {
    ...options,
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

const sousNativeStake = async (sousId: number, sousChefContract, amount: string, decimals = 18) => {
  const gasPrice = await getGasPrice()
  const tx = await sousChefContract.stake(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString(), {
    ...options,
    value: new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString(),
    gasPrice,
  })
  const receipt = await tx.wait()
  return receipt.status
}

const useStakePool = (sousId: number) => {
  const dispatch = useAppDispatch()
  const { account, chainId } = useWeb3React()
  const sousChefContract = useSousChef(sousId)
  const pool = getPoolById(sousId)
  const handleStake = useCallback(
    async (amount: string, decimals: number) => {
      if (pool.isNative) {
        await sousNativeStake(sousId, sousChefContract, amount, decimals)
      } else {
        await sousStake(sousId, sousChefContract, amount, decimals)
      }

      dispatch(updateUserStakedBalance(chainId, sousId, account))
      dispatch(updateUserBalance(chainId, sousId, account))
    },
    [account, dispatch, sousChefContract, chainId, sousId, pool.isNative],
  )

  return { onStake: handleStake }
}

export default useStakePool

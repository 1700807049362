import tokens from '../tokens'
import { PoolCategory, PoolConfig } from '../types'

export const ethPools: PoolConfig[] = [
  // {
  //   chainId: 1,
  //   sousId: 1004,
  //   stakingToken: tokens.dope,
  //   earningToken: tokens.dope,
  //   contractAddress: {
  //     97: '0x8BBc65A07FF1f15790Fd6E54B9911dC1f3D83970',
  //     56: '0x8BBc65A07FF1f15790Fd6E54B9911dC1f3D83970',
  //     1: '0x8BBc65A07FF1f15790Fd6E54B9911dC1f3D83970',
  //   },
  //   isCustomReward: false,
  //   customRewardAddress: {
  //     97: '0x8BBc65A07FF1f15790Fd6E54B9911dC1f3D83970',
  //     56: '0x8BBc65A07FF1f15790Fd6E54B9911dC1f3D83970',
  //     1: '0x8BBc65A07FF1f15790Fd6E54B9911dC1f3D83970',
  //   },
  //   depositAddress: {
  //     97: '0xa25b2d664abb432af956bb5087e1100428ceceb8',
  //     56: '0xa25b2d664abb432af956bb5087e1100428ceceb8',
  //     1: '0xa25b2d664abb432af956bb5087e1100428ceceb8',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   isLock: false,
  //   lockTime: 365,
  //   harvest: true,
  //   rate: 4,
  //   tokenPerBlock: '10',
  //   sortOrder: 3,
  //   isFinished: false,
  // },
  // {
  //   chainId: 1,
  //   sousId: 1005,
  //   stakingToken: tokens.dope,
  //   earningToken: tokens.dope,
  //   customRewardAddress: {
  //     97: '0x342b83e4ba2e78ae1a08b64c684bbf00b1831f37',
  //     1: '0x342b83e4ba2e78ae1a08b64c684bbf00b1831f37',
  //   },
  //   isCustomReward: false,
  //   contractAddress: {
  //     97: '0x342b83e4ba2e78ae1a08b64c684bbf00b1831f37',
  //     1: '0x342b83e4ba2e78ae1a08b64c684bbf00b1831f37',
  //   },
  //   depositAddress: {
  //     97: '0x3216b28CE0fc43d8A427CaEA9A301E1714E4BC03',
  //     1: '0x3216b28CE0fc43d8A427CaEA9A301E1714E4BC03',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   isLock: false,
  //   lockTime: 365,
  //   harvest: true,
  //   lockInfo: 'Only for DopePuffPass Holders',
  //   rate: 6,
  //   tokenPerBlock: '10',
  //   sortOrder: 3,
  //   isFinished: false,
  // },
  // {
  //   chainId: 1,
  //   sousId: 1006,
  //   stakingToken: tokens.dope,
  //   earningToken: tokens.dope,
  //   customRewardAddress: {
  //     97: '0xB52e77437bA61f4c10C90a2083366AFa68F888bA',
  //     1: '0xB52e77437bA61f4c10C90a2083366AFa68F888bA',
  //   },
  //   isCustomReward: false,
  //   contractAddress: {
  //     97: '0xB52e77437bA61f4c10C90a2083366AFa68F888bA',
  //     1: '0xB52e77437bA61f4c10C90a2083366AFa68F888bA',
  //   },
  //   depositAddress: {
  //     97: '0x593C181C95B84f46312021BfffDC71AbF0267456',
  //     1: '0x593C181C95B84f46312021BfffDC71AbF0267456',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   isLock: true,
  //   lockTime: 30,
  //   harvest: true,
  //   rate: 8,
  //   tokenPerBlock: '10',
  //   sortOrder: 3,
  //   isFinished: false,
  // },
  // {
  //   chainId: 1,
  //   sousId: 1008,
  //   stakingToken: tokens.dope,
  //   earningToken: tokens.dope,
  //   contractAddress: {
  //     97: '0x0c2c91fc9827787f66a7cd14b8f1e5c36a5d8f70',
  //     56: '0x0c2c91fc9827787f66a7cd14b8f1e5c36a5d8f70',
  //   },
  //   depositAddress: {
  //     97: '0x96b188C57acdd0e41606B469d1fDAF8410CAc3c3',
  //     56: '0x96b188C57acdd0e41606B469d1fDAF8410CAc3c3',
  //   },
  //   poolCategory: PoolCategory.CORE,
  //   isLock: true,
  //   lockTime: 30,
  //   lockInfo: 'Only for DopePuffPass Holders',
  //   harvest: true,
  //   rate: 10,
  //   tokenPerBlock: '10',
  //   sortOrder: 3,
  //   isFinished: false,
  // },
]

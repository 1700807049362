import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('portal/selectCurrency')
export const typeInput = createAction<{ field: Field; typedValue: string }>('portal/typeInput')
export const setGas = createAction<{ withGas: boolean }>('portal/setGas')
export const replacePortalState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  recipient: string | null
  withGas: boolean
}>('portal/replacPortalState')
export const setRecipient = createAction<{ recipient: string | null }>('portal/setRecipient')

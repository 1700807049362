import React from 'react'
import {
  TokenPairImage as UIKitTokenPairImage,
  TokenPairImageProps as UIKitTokenPairImageProps,
  TokenImage as UIKitTokenImage,
  ImageProps,
} from '@arborswap/uikit'
import { Token } from 'config/constants/types'
import { getAddress } from 'utils/addressHelpers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getNativeName } from 'utils/getNativeName'
import getTokenLogoURL, { getNativeLogoURL } from 'utils/getTokenLogoURL'

interface TokenPairImageProps extends Omit<UIKitTokenPairImageProps, 'primarySrc' | 'secondarySrc'> {
  primaryToken: Token
  secondaryToken: Token
}

const getImageUrlFromToken = (chainId: number, token: Token) => {
  return token.symbol === getNativeName(chainId)
    ? getNativeLogoURL(token.symbol)
    : getTokenLogoURL(chainId, getAddress(chainId, token.address))
}

export const TokenPairImage: React.FC<TokenPairImageProps> = ({ primaryToken, secondaryToken, ...props }) => {
  const { chainId } = useActiveWeb3React()
  return (
    <UIKitTokenPairImage
      primarySrc={getImageUrlFromToken(chainId, primaryToken)}
      secondarySrc={getImageUrlFromToken(chainId, secondaryToken)}
      {...props}
    />
  )
}

interface TokenImageProps extends ImageProps {
  token: Token
}

export const TokenImage: React.FC<TokenImageProps> = ({ token, ...props }) => {
  const { chainId } = useActiveWeb3React()
  return <UIKitTokenImage src={getImageUrlFromToken(chainId, token)} {...props} />
}

export default {
  masterChef: {
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  nativeWrapped: {
    1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    158: '0x86D1D92E543220Bd2F91Ec01694100043a25675A',
    159: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
  },
  dexManagement: {
    1: '0x1a88a6D94d725c75BA868735F1a750774b3aD3c2',
    97: '0x8a6CfdBc5d74e711b2AC98CC2Cf6679F3f442E22',
    56: '0x1A55251C7aeD192f038e20572f1d6596FEa87154',
    158: '0xE7deC0d917ecB4b5d21EEDdf058A571C4C0Ebd57',
    159: '0xdC625EfE2aA5AD783F92d72e404359C1F00ef897',
  },
  routerAddress: {
    1: '0x64Ef108DC6Ce6fffEa50560D45bce5Feff62f6df',
    97: '0x6Ff49d97386E9F7783e2EC54F229621965B1DEC5',
    56: '0x3027Ae348394349886f963D35786e5F468A221DE',
    158: '0x5ee8fAEa07caaE4e344EF2D5BFaA0C2DcF51Ea9f',
    159: '0x9dDAB3Aa5054c812F269Ba6Df007084087D9285f',
  },
  foreignPortal: {
    1: '0x23abf41f7af16a9c1c9c623c7e4c9dadedfc0d3e',
    56: '0xD7800C5E6E094c68235e370897D318Cde411f9eB',
    97: '0x7DcF50ea8ceeA69E9235e6d1ADed06F4c4403f25',
  },
  routerContract: {
    1: '0x64Ef108DC6Ce6fffEa50560D45bce5Feff62f6df',
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    158: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
    159: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  multiCall: {
    1: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    159: '0xbD165449C0e33c5E3a6d5c50C3381E9DA1e4E72a',
    158: '0x6eFEC35906F015b704C29EECE2E403fD6A587743',
  },
  pancakeProfile: {
    56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
  },
  pancakeRabbits: {
    56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
  },
  bunnyFactory: {
    56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '',
  },
  pointCenterIfo: {
    56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
  },
  tradingCompetition: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
  },
  cakeVault: {
    56: '0xa80240Eb5d7E05d3F250cF000eEc0891d00b51CC',
    97: '',
  },
  predictions: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '',
  },
  chainlinkOracle: {
    56: '0xD276fCF34D54A926773c399eBAa772C12ec394aC',
    97: '',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
}

import tokens from '../tokens'
import { PoolCategory, PoolConfig } from '../types'

export const rbaPools: PoolConfig[] = [
  // {
  //   isNative: true,
  //   isNativeEarning: true,
  //   chainId: 158,
  //   sousId: 158102,
  //   stakingToken: tokens.wrba,
  //   earningToken: tokens.wrba,
  //   contractAddress: {
  //     158: '0x85CD4bBa640A6eBC746D6AC3f85f4b32b1918d69',
  //   },
  //   depositAddress: {
  //     158: '0x1661C9e6dD971f5613651F58Bf141eE9839bF880',
  //   },
  //   customRewardAddress: {
  //     158: '0xA4523e0De377e45FFea13122ce479A927B3acCbd',
  //   },
  //   poolCategory: PoolCategory.NATIVECOIN,
  //   isLock: false,
  //   lockTime: 730,
  //   harvest: true,
  //   // lockInfo: 'Unlocks on mainnet',
  //   rate: 1,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  //   isDisabled: false,
  //   isMembership: false,
  //   isCustomReward: false,
  // },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 158,
    sousId: 158103,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      158: '0x1D298a20eD717D740925D5944464aeD163699ed9',
    },
    depositAddress: {
      158: '0x8172e41287368eED1B421728BeAce4f1f7e9D22a',
    },
    customRewardAddress: {
      158: '0xbF7ee5BA180c3421EF815806aa6ff799AFDD785D',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: true,
    lockTime: 365,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 3,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 158,
    sousId: 158104,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      158: '0xD25Ab71ADFC863F23a1196283b0930de754daBaf',
    },
    depositAddress: {
      158: '0x434a9168830cd061E6149DE6ce50F8ADc80d6B9A',
    },
    customRewardAddress: {
      158: '0x7F8899451Ca9857a7Be3FEc69f0E5e4bBefe4a2B',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: true,
    lockTime: 365 * 3,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 4,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 158,
    sousId: 158105,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      158: '0x76A0B832faDb3f2541daC72770cc6438b26B1759',
    },
    depositAddress: {
      158: '0x2F04276f4a131D6B8d07f9D30CA1978F5Edb3E4E',
    },
    customRewardAddress: {
      158: '0x87674fF26e5882afCbb9b3B7E0CE3d61784A6Ecc',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: true,
    lockTime: 365 * 5,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 6,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 158,
    sousId: 158106,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      158: '0xbFf92d4D88830713E3aC3168A42AF75D5C7329f9',
    },
    depositAddress: {
      158: '0x8dD876d5eD2BBE554524ef6f0Ed19F3c703b772d',
    },
    customRewardAddress: {
      158: '0xd2de3C6830c31a387c72af4E476012994c73B394',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: true,
    lockTime: 365 * 2,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 12,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: true,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 158,
    sousId: 158107,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      158: '0xbDe4b0003154991fC531a7788141069F77d794a9',
    },
    depositAddress: {
      158: '0xE4c1E5dD6615D4bb0D0a07C85DA3C297DA77CdA7',
    },
    customRewardAddress: {
      158: '0xb4aD360F971D722eB97C6a70c6aDe0B0AC716B27',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: true,
    lockTime: 365 * 3,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 5,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: true,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 158,
    sousId: 158108,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      158: '0x37A6136a2dF4b6c42693342eA48A173aC53e1Df7',
    },
    depositAddress: {
      158: '0x61225bd5c5928fDbcAa21aBBF338e49043444f9b',
    },
    customRewardAddress: {
      158: '0x19ECd0a02794636FD0A34D2537e0b1db660209ae',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: true,
    lockTime: 365 * 5,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 8,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: true,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
]

export const rbaTestnetPools: PoolConfig[] = [
  {
    isNative: false,
    chainId: 159,
    sousId: 159001,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      159: '0x34FB08a71656B8e3DaC4736a4edb7c27b9C11796',
    },
    depositAddress: {
      159: '0x34FB08a71656B8e3DaC4736a4edb7c27b9C11796',
    },
    customRewardAddress: {
      159: '0x34FB08a71656B8e3DaC4736a4edb7c27b9C11796',
    },
    poolCategory: PoolCategory.CORE,
    isLock: false,
    lockTime: 365,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 5,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 159,
    sousId: 159102,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      159: '0x0020ac2449bA784D5A4Fafb26Fe8E4459bFF0F2d',
    },
    depositAddress: {
      159: '0xD4a3515cB491ADFDA6F44Eba86ed138a239ebE7f',
    },
    customRewardAddress: {
      159: '0x0Fb9874Ee841854E9587155ABfD9f5cdc10617D2',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: false,
    lockTime: 730,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 1,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 159,
    sousId: 159103,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      159: '0x434f180C46E818FFA63020ED9f5F1019A2c0c83b',
    },
    depositAddress: {
      159: '0x33A6d06BC654e8BBC1C3Cf73D44c8EeD21Cae561',
    },
    customRewardAddress: {
      159: '0x0B14C01a65302fe65bBb54E8C530110bED3468f5',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: true,
    lockTime: 365,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 3,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 159,
    sousId: 159104,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      159: '0x21eb6941ECa99965d6085995ca868d7E4F37787D',
    },
    depositAddress: {
      159: '0x7dd2c034Cf9A57e2782857F565E7A0caE4366Bf4',
    },
    customRewardAddress: {
      159: '0x87B49aAA16Ba6F75E4E8ff85b8C4738a8F220f2C',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: true,
    lockTime: 365 * 3,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 4,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  {
    isNative: true,
    isNativeEarning: true,
    chainId: 159,
    sousId: 159105,
    stakingToken: tokens.wrba,
    earningToken: tokens.wrba,
    contractAddress: {
      159: '0x2CBf3E9eD29730543A234Ce290C8353b2dd1e4E1',
    },
    depositAddress: {
      159: '0xf941BEbc178915551f2DCaF0e7186441B242B1c0',
    },
    customRewardAddress: {
      159: '0x962430933E366d265b32c864b61B25e7d8660433',
    },
    poolCategory: PoolCategory.NATIVECOIN,
    isLock: true,
    lockTime: 365 * 5,
    harvest: true,
    // lockInfo: 'Unlocks on mainnet',
    rate: 6,
    tokenPerBlock: '10',
    sortOrder: 2,
    isFinished: false,
    isDisabled: false,
    isMembership: false,
    isCustomReward: false,
  },
  // {
  //   isNative: true,
  //   isNativeEarning: true,
  //   chainId: 159,
  //   sousId: 159106,
  //   stakingToken: tokens.wrba,
  //   earningToken: tokens.wrba,
  //   contractAddress: {
  //     159: '0xf16A2721ec427E6F80120813dbd96C6CF58251DA',
  //   },
  //   depositAddress: {
  //     159: '0xb08416e9A7C1F756B9A1C11D044E6a5c763A92C8',
  //   },
  //   customRewardAddress: {
  //     159: '0xDf5263A1921b87Bd3Dfd06DF393c3CB10A22021B',
  //   },
  //   poolCategory: PoolCategory.NATIVECOIN,
  //   isLock: true,
  //   lockTime: 365,
  //   harvest: true,
  //   // lockInfo: 'Unlocks on mainnet',
  //   rate: 3,
  //   tokenPerBlock: '10',
  //   sortOrder: 2,
  //   isFinished: true,
  //   isDisabled: false,
  //   isMembership: false,
  //   isCustomReward: false,
  // },
  // {
  //   isNative: true,
  //   isNativeEarning: true,
  //   chainId: 159,
  //   sousId: 159107,
  //   stakingToken: tokens.wrba,
  //   earningToken: tokens.wrba,
  //   contractAddress: {
  //     159: '0x7bbd00fC046fD76Ae152c304af2cd53F4083f8b4',
  //   },
  //   depositAddress: {
  //     159: '0xD812cF61343bE68b80835A3B84F57bab7E206A08',
  //   },
  //   customRewardAddress: {
  //     159: '0x077861C795E9ca88A3F0cbE3984eB62E95326532',
  //   },
  //   poolCategory: PoolCategory.NATIVECOIN,
  //   isLock: true,
  //   lockTime: 365 * 3,
  //   harvest: true,
  //   // lockInfo: 'Unlocks on mainnet',
  //   rate: 4,
  //   tokenPerBlock: '10',
  //   sortOrder: 2,
  //   isFinished: true,
  //   isDisabled: false,
  //   isMembership: false,
  //   isCustomReward: false,
  // },
  // {
  //   isNative: true,
  //   isNativeEarning: true,
  //   chainId: 159,
  //   sousId: 159108,
  //   stakingToken: tokens.wrba,
  //   earningToken: tokens.wrba,
  //   contractAddress: {
  //     159: '0xBEf4Bf0f83d26b9B82c5eD6201fA40a69e8E5C91',
  //   },
  //   depositAddress: {
  //     159: '0x5F8ca5D330aD037De84f5c3e6faf8F00f2642c8B',
  //   },
  //   customRewardAddress: {
  //     159: '0xB5A7596369213b7Ee386c3f70E6B4a4BF64b79a4',
  //   },
  //   poolCategory: PoolCategory.NATIVECOIN,
  //   isLock: true,
  //   lockTime: 365 * 5,
  //   harvest: true,
  //   // lockInfo: 'Unlocks on mainnet',
  //   rate: 6,
  //   tokenPerBlock: '10',
  //   sortOrder: 2,
  //   isFinished: true,
  //   isDisabled: false,
  //   isMembership: false,
  //   isCustomReward: false,
  // },
]

import React from 'react'
import { Flex } from '@arborswap/uikit'
import MenuButton from './MenuButton'

const NetworkDropdown = () => {
  return (
    <Flex>
      <MenuButton />
    </Flex>
  )
}

export default NetworkDropdown

import { ChainId } from '@arborswap/sdk'
import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (chainId: number, address: Address): string => {
  return address[chainId] ? address[chainId] : address[ChainId.ETHEREUM]
}

export const getMasterChefAddress = (chainId: number) => {
  return addresses.masterChef[chainId]
}

export const getDexManagementAddress = (chainId: number) => {
  return addresses.dexManagement[chainId]
}
export const getRouterAddress = (chainId: number) => {
  return addresses.routerAddress[chainId]
}

export const getPortalAddress = (chainId: number) => {
  return addresses.foreignPortal[chainId]
}

export const getMulticallAddress = (chainId: number) => {
  return addresses.multiCall[chainId]
}
export const getWbnbAddress = (chainId: number) => {
  return tokens.wbnb[chainId].address
}

export const getWrappedNativeAddress = (chainId: number) => {
  return getAddress(chainId, tokens.wbnb.address)
}

export const getChainlinkOracleAddress = (chainId: number) => {
  return getAddress(chainId, addresses.chainlinkOracle)
}

import React, { useEffect } from 'react'
import { Web3ReactHooks, Web3ReactProvider, useWeb3React } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import useEagerConnect from 'hooks/useEagerConnect'
import { listOfConnections } from 'utils/connection'
import { useLocation } from 'react-router-dom'
import { useNetworkChainId } from 'state/hooks'

const Web3Provider = ({ children }: { children: React.ReactNode }) => {
  const connectors: [Connector, Web3ReactHooks][] = listOfConnections.map(({ hooks, connector }) => [connector, hooks])

  useEagerConnect()

  return <Web3ReactProvider connectors={connectors}>{children}</Web3ReactProvider>
}

function Updater() {
  const { account, chainId, connector, provider } = useWeb3React()
  const stateChainId = useNetworkChainId()

  useEffect(() => {
    if (chainId !== stateChainId) {
      connector.deactivate()
    }
  }, [chainId, stateChainId, connector])

  return null
}

export default Web3Provider

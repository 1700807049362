import BigNumber from 'bignumber.js'
import poolsConfig from 'config/constants/pools'
import sousChefABI from 'config/abi/sousChef.json'
import cakeABI from 'config/abi/cake.json'
import wbnbABI from 'config/abi/weth.json'
import multicall from 'utils/multicall'
import { getAddress, getWrappedNativeAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getNativeSymbol } from 'utils/getNativeName'

export const fetchPoolsBlockLimits = async (chainId: number) => {
  const poolsWithEnd = poolsConfig.filter((p) => p.sousId !== 100000000)
  const callsStartBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(chainId, poolConfig.contractAddress),
      name: 'startBlock',
    }
  })
  const callsEndBlock = poolsWithEnd.map((poolConfig) => {
    return {
      address: getAddress(chainId, poolConfig.contractAddress),
      name: 'bonusEndBlock',
    }
  })

  const starts = await multicall(chainId, sousChefABI, callsStartBlock)
  const ends = await multicall(chainId, sousChefABI, callsEndBlock)

  return poolsWithEnd.map((cakePoolConfig, index) => {
    const startBlock = starts[index]
    const endBlock = ends[index]
    return {
      sousId: cakePoolConfig.sousId,
      startBlock: new BigNumber(startBlock).toJSON(),
      endBlock: new BigNumber(endBlock).toJSON(),
    }
  })
}

export const fetchPoolsTotalStaking = async (chainId: number) => {
  const nonBnbPools = poolsConfig.filter((p) => p.chainId === chainId).filter((p) => !p.isNative)

  const bnbPool = poolsConfig.filter((p) => p.chainId === chainId).filter((p) => p.isNative)

  const callsNonBnbPools = nonBnbPools.map((poolConfig) => {
    return {
      address: getAddress(chainId, poolConfig.stakingToken.address),
      name: 'balanceOf',
      params: [getAddress(chainId, poolConfig.contractAddress)],
    }
  })

  const callsBnbPools = bnbPool.map((poolConfig) => {
    return {
      address: getWrappedNativeAddress(chainId),
      name: 'balanceOf',
      params: [getAddress(chainId, poolConfig.contractAddress)],
    }
  })

  const nonBnbPoolsTotalStaked = await multicall(chainId, cakeABI, callsNonBnbPools)
  const bnbPoolsTotalStaked = await multicall(chainId, wbnbABI, callsBnbPools)

  return [
    ...nonBnbPools.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(nonBnbPoolsTotalStaked[index]).toJSON(),
    })),
    ...bnbPool.map((p, index) => ({
      sousId: p.sousId,
      totalStaked: new BigNumber(bnbPoolsTotalStaked[index]).toJSON(),
    })),
  ]
}

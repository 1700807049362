import { useEffect, useMemo } from 'react'
// import useActiveWeb3React from 'hooks/useActiveWeb3React'
// import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import { useNetworkChainId } from 'state/hooks'
import { useAppDispatch } from 'state'
import useRefresh from 'hooks/useRefresh'
import getProvider from 'utils/getProvider'
import { fetchPoolsPublicDataAsync, fetchPoolsUserDataAsync } from '.'
import { State, Pool } from '../types'
import { transformPool } from './helpers'

export const useFetchPublicPoolsData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const chainId = useNetworkChainId()
  const library = getProvider(chainId)

  useEffect(() => {
    const fetchPoolsPublicData = async () => {
      const blockNumber = await library.getBlockNumber()
      dispatch(fetchPoolsPublicDataAsync(chainId, blockNumber))
    }

    fetchPoolsPublicData()
  }, [dispatch, chainId, library, slowRefresh])
}

export const usePools = (account): { pools: Pool[]; userDataLoaded: boolean } => {
  const { fastRefresh } = useRefresh()
  const chainId = useNetworkChainId()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (account) {
      dispatch(fetchPoolsUserDataAsync(chainId, account))
    }
  }, [account, dispatch, fastRefresh, chainId])

  const { pools, userDataLoaded } = useSelector((state: State) => ({
    pools: state.pools.data,
    userDataLoaded: state.pools.userDataLoaded,
  }))
  return { pools: pools.map(transformPool), userDataLoaded }
}

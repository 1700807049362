import { Token, TokenAmount } from '@arborswap/sdk'
import { useQuery } from 'react-query'
import { useMemo } from 'react'
import { formatInputToStringValue } from 'utils/bigNumber'
import { BigNumber as BN, Contract } from 'ethers'

import { usePortalContract } from './useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import useActiveWeb3React from './useActiveWeb3React'

type FeeType = BN | undefined
const FEE_DENOMINATOR = BN.from(10000)

export const portalFee = async (contract: Contract) => {
  try {
    const fee = (await contract.callStatic.fee()) as unknown as BN
    return fee
  } catch (e) {
    return undefined
  }
}

const portalTokenFee = async (contract: Contract, token: Token) => {
  if (!token) return undefined
  try {
    const fee = (await contract.callStatic.gasFee(token.address)) as unknown as BN
    return fee
  } catch (e) {
    return undefined
  }
}

export const usePortalFee = () => {
  const contract = usePortalContract()
  const { chainId } = useActiveWeb3React()
  const query = useQuery(['portalFee', chainId], () => portalFee(contract))

  return query
}

export const usePortalTokenFee = (token: Token) => {
  const contract = usePortalContract()
  const { chainId } = useActiveWeb3React()
  const query = useQuery(['portalTokenFee', chainId, token?.address], () => portalTokenFee(contract, token))

  return query
}

const calculateRelayFee = (amount: string, fee: FeeType, gasFee: FeeType, withGas: string) => {
  const amountIn = BN.from(amount)
  let feeAmount: FeeType
  let receiveAmount: FeeType

  if (amountIn.lte(BN.from(0)) || !fee || !gasFee) {
    feeAmount = BN.from(0)
    receiveAmount = BN.from(0)
  } else {
    feeAmount = amountIn.mul(fee).div(FEE_DENOMINATOR)
    receiveAmount = withGas === '1' ? amountIn.sub(feeAmount).sub(gasFee) : amountIn.sub(feeAmount)
  }

  return {
    feeAmountBN: feeAmount,
    receiveAmountBN: receiveAmount,
  }
}

export interface PortalRelayInfo {
  feeAmount: TokenAmount
  receiveAmount: TokenAmount
}

function useRelayPortal(token?: Token, amount?: string, withGas?: string): PortalRelayInfo | undefined {
  const amountIn = formatInputToStringValue(amount, token?.decimals)
  const fee = usePortalFee().data
  const gasFee = usePortalTokenFee(token).data

  // const results = useSingleCallResult(contract, 'calculateRelayFee', inputs).result

  return useMemo(() => {
    if (!token || !withGas || !fee || !gasFee || !amountIn) {
      return undefined
    }
    const { feeAmountBN, receiveAmountBN } = calculateRelayFee(amountIn, fee, gasFee, withGas)

    const feeAmount = new TokenAmount(token, feeAmountBN.toString())
    const receiveAmount = new TokenAmount(token, receiveAmountBN.toString())

    return {
      feeAmount,
      receiveAmount,
    }
  }, [token, withGas, fee, gasFee, amountIn])
}

export default useRelayPortal

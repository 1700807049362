import { ChainId, Token } from '@arborswap/sdk'

export const WRBA: { [chainId: number]: Token } = {
  [ChainId.ROBURNA_TESTNET]: new Token(
    ChainId.ROBURNA_TESTNET,
    '0x38aed14869Df0B10abCE55b6de03Ae168b5c1eeF',
    18,
    'WRBA',
    'WRAPPED ROBURNA',
  ),
  [ChainId.ROBURNA]: new Token(
    ChainId.ROBURNA,
    '0x86D1D92E543220Bd2F91Ec01694100043a25675A',
    18,
    'WRBA',
    'WRAPPED ROBURNA',
  ),
}

export const CAKE: { [chainId: number]: Token } = {
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'Pancake Token',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    18,
    'CAKE',
    'ArborSwap Token',
  ),
}

export const RBA: { [chainId: number]: Token } = {
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'ArborSwap Token',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x430EA547076C0051B252b1ab1ef83cd83F2D1aB4',
    18,
    'RBA',
    'Roburna Token',
  ),
}

export const F4H: { [chainId: number]: Token } = {
  [ChainId.ROBURNA_TESTNET]: new Token(
    ChainId.ROBURNA_TESTNET,
    '0x663D29e38A2b873f1E4E5077Bb1C5e1f33b0D318',
    18,
    'F4H',
    'Fight4Hope Token',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x6Ef84972B6d58e78b9a4512D808eb87F18A308a6',
    18,
    'F4H',
    'Fight4Hope Token',
  ),
}

export const BUSD: { [chainId: number]: Token } = {
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}

export const WBNB = new Token(ChainId.ETHEREUM, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB')
export const DAI = new Token(
  ChainId.ETHEREUM,
  '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  18,
  'DAI',
  'Dai Stablecoin',
)
export const USDT: { [chainId: number]: Token } = {
  [ChainId.ETHEREUM]: new Token(
    ChainId.ETHEREUM,
    '0xdac17f958d2ee523a2206206994597c13d831ec7',
    18,
    'USDT',
    'Tether USD',
  ),
  [ChainId.BSC]: new Token(ChainId.BSC, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD'),
  [ChainId.ROBURNA_TESTNET]: new Token(
    ChainId.ROBURNA_TESTNET,
    '0x3ef19D1142b00084D16df47eeC633bc3134dC359',
    18,
    'USDT',
    'Tether USD',
  ),
  [ChainId.ROBURNA]: new Token(ChainId.ROBURNA, '0xF183CbD16ca8C4ebc5c51908Fd92EbB89De687C7', 18, 'USDT', 'Tether USD'),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
  ),
}

export const USDC: { [chainId: number]: Token } = {
  [ChainId.ETHEREUM]: new Token(ChainId.ETHEREUM, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 18, 'USDC', 'USD Coin'),
  [ChainId.BSC]: new Token(
    ChainId.BSC,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDC',
    'Binance-Peg USD Coin',
  ),
  [ChainId.ROBURNA_TESTNET]: new Token(
    ChainId.ROBURNA_TESTNET,
    '0x3ef19D1142b00084D16df47eeC633bc3134dC359',
    18,
    'USDC',
    'Rba-Peg USD Coin',
  ),
  [ChainId.ROBURNA]: new Token(
    ChainId.ROBURNA,
    '0x21F2fb5De15Ab4f2101023698355D27403bdB543',
    18,
    'USDC',
    'Rba-Peg USD Coin',
  ),
  [ChainId.BSC_TESTNET]: new Token(
    ChainId.BSC_TESTNET,
    '0x64544969ed7EBf5f083679233325356EbE738930',
    18,
    'USDC',
    'Binance-Peg USD Coin',
  ),
}

export const BTCB = new Token(ChainId.ETHEREUM, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const UST = new Token(
  ChainId.ETHEREUM,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token',
)
export const ETH = new Token(
  ChainId.ETHEREUM,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token',
)

const tokens = {
  rba: {
    symbol: 'RBA',
    address: {
      56: '0xDD534480782eCf53e4A5257B0f3C37702A0bAD61',
      97: '0x430EA547076C0051B252b1ab1ef83cd83F2D1aB4',
      159: '0x430EA547076C0051B252b1ab1ef83cd83F2D1aB4',
      158: '0x430EA547076C0051B252b1ab1ef83cd83F2D1aB4',
    },
    projectLink: 'https://www.roburna.com/',
  },
  dope: {
    symbol: 'DOPE',
    address: {
      1: '0x9D024AE29114b7C11843312877d58B9939F6aeB2',
      56: '0x9D024AE29114b7C11843312877d58B9939F6aeB2',
      97: '0x083295D160A243D0A850Bc75E317e1c56f935EA9',
      159: '0xFAe063159b4d83d8491271a165eECa5632E4c288',
    },
    decimals: 18,
    projectLink: 'http://dopereum.com/',
  },
  wrba: {
    symbol: 'WRBA',
    address: {
      159: '0x38aed14869Df0B10abCE55b6de03Ae168b5c1eeF',
    },
    decimals: 18,
    projectLink: 'https://www.roburna.com/',
  },
  bnb: {
    symbol: 'BNB',
    projectLink: 'https://www.binance.com/',
  },
  cake: {
    symbol: 'CAKE',
    address: {
      56: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
      97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },
  f4h: {
    symbol: 'F4H',
    address: {
      // 56: '0xa5F8128d04692656a60f17B349C2146c48e6863a',
      // 97: '0x083295D160A243D0A850Bc75E317e1c56f935EA9',
      159: '0x663D29e38A2b873f1E4E5077Bb1C5e1f33b0D318',
    },
    decimals: 18,
    projectLink: 'https://fight4hope.io/',
  },
  mangaai: {
    symbol: 'MANGA',
    address: {
      56: '0xda022bf4402f3edf32b02356056400e8d7ef5522',
      97: '0x3e1e5369e37114C060d933773fEc8ba0d9e7Bad9',
      159: '0xFAe063159b4d83d8491271a165eECa5632E4c288',
    },
    decimals: 18,
    projectLink: 'https://mangaai.org/',
  },
  TTN: {
    symbol: 'TTN',
    address: {
      56: '0x50a9eb8a53f2c2993f46b354bd5f24f1c880bf24',
      97: '0x50a9eb8a53f2c2993f46b354bd5f24f1c880bf24',
      159: '0xFAe063159b4d83d8491271a165eECa5632E4c288',
    },
    decimals: 9,
    projectLink: 'http://teletreon.com/',
  },
  wbnb: {
    symbol: 'WBNB',
    address: {
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
      97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },

  busd: {
    symbol: 'BUSD',
    address: {
      56: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  eth: {
    symbol: 'ETH',
    address: {
      56: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/',
  },

  syrup: {
    symbol: 'SYRUP',
    address: {
      56: '0x009cF7bC57584b7998236eff51b98A168DceA9B0',
      97: '0xfE1e507CeB712BDe086f3579d2c03248b2dB77f9',
    },
    decimals: 18,
    projectLink: 'https://pancakeswap.finance/',
  },

  pha: {
    symbol: 'PHA',
    address: {
      56: '0x0112e557d400474717056C4e6D40eDD846F38351',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://phala.network',
  },

  ten: {
    symbol: 'TEN',
    address: {
      56: '0xdFF8cb622790b7F92686c722b02CaB55592f152C',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.tenet.farm/',
  },
  balbt: {
    symbol: 'bALBT',
    address: {
      56: '0x72fAa679E1008Ad8382959FF48E392042A8b06f7',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://allianceblock.io/',
  },
  asr: {
    symbol: 'ASR',
    address: {
      56: '0x80D5f92C2c8C682070C95495313dDB680B267320',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  atm: {
    symbol: 'ATM',
    address: {
      56: '0x25E9d05365c867E59C1904E7463Af9F312296f9E',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  og: {
    symbol: 'OG',
    address: {
      56: '0xf05E45aD22150677a017Fbd94b84fBB63dc9b44c',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  reef: {
    symbol: 'REEF',
    address: {
      56: '0xF21768cCBC73Ea5B6fd3C687208a7c2def2d966e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://reef.finance/',
  },
  ditto: {
    symbol: 'DITTO',
    address: {
      56: '0x233d91A0713155003fc4DcE0AFa871b508B3B715',
      97: '',
    },
    decimals: 9,
    projectLink: 'https://ditto.money/',
  },
  juv: {
    symbol: 'JUV',
    address: {
      56: '0xC40C9A843E1c6D01b7578284a9028854f6683b1B',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  psg: {
    symbol: 'PSG',
    address: {
      56: '0xBc5609612b7C44BEf426De600B5fd1379DB2EcF1',
      97: '',
    },
    decimals: 2,
    projectLink: 'https://www.chiliz.com',
  },
  vai: {
    symbol: 'VAI',
    address: {
      56: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://venus.io/',
  },

  blink: {
    symbol: 'BLINK',
    address: {
      56: '0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://blink.wink.org',
  },
  unfi: {
    symbol: 'UNFI',
    address: {
      56: '0x728C5baC3C3e370E372Fc4671f9ef6916b814d8B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://unifiprotocol.com',
  },
  twt: {
    symbol: 'TWT',
    address: {
      56: '0x4B0F1812e5Df2A09796481Ff14017e6005508003',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://trustwallet.com/',
  },
  hard: {
    symbol: 'HARD',
    address: {
      56: '0xf79037F6f6bE66832DE4E7516be52826BC3cBcc4',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://hard.kava.io',
  },
  broobee: {
    symbol: 'bROOBEE',
    address: {
      56: '0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://roobee.io/',
  },
  stax: {
    symbol: 'STAX',
    address: {
      56: '0x0Da6Ed8B13214Ff28e9Ca979Dd37439e8a88F6c4',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://stablexswap.com/',
  },
  nar: {
    symbol: 'NAR',
    address: {
      56: '0xA1303E6199b319a891b79685F0537D289af1FC83',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://narwhalswap.org/',
  },
  nya: {
    symbol: 'NYA',
    address: {
      56: '0xbFa0841F7a90c4CE6643f651756EE340991F99D5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://nyanswop.org/',
  },
  ctk: {
    symbol: 'CTK',
    address: {
      56: '0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
      97: '',
    },
    decimals: 6,
    projectLink: 'https://www.certik.foundation/',
  },
  inj: {
    symbol: 'INJ',
    address: {
      56: '0xa2B726B1145A4773F68593CF171187d8EBe4d495',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://injectiveprotocol.com/',
  },
  sxp: {
    symbol: 'SXP',
    address: {
      56: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://swipe.io/',
  },
  alpha: {
    symbol: 'ALPHA',
    address: {
      56: '0xa1faa113cbE53436Df28FF0aEe54275c13B40975',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://alphafinance.io/',
  },
  xvs: {
    symbol: 'XVS',
    address: {
      56: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://venus.io/',
  },
  sushi: {
    symbol: 'SUSHI',
    address: {
      56: '0x947950BcC74888a40Ffa2593C5798F11Fc9124C4',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://sushi.com/',
  },
  comp: {
    symbol: 'COMP',
    address: {
      56: '0x52CE071Bd9b1C4B00A0b92D298c512478CaD67e8',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://compound.finance/',
  },

  bifi: {
    symbol: 'BIFI',
    address: {
      56: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://beefy.finance/',
  },
  dusk: {
    symbol: 'DUSK',
    address: {
      56: '0xB2BD0749DBE21f623d9BABa856D3B0f0e1BFEc9C',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://dusk.network/',
  },

  beth: {
    symbol: 'BETH',
    address: {
      56: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ethereum.org/en/eth2/beacon-chain/',
  },
  mamzn: {
    symbol: 'mAMZN',
    address: {
      56: '0x3947B992DC0147D2D89dF0392213781b04B25075',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  mgoogl: {
    symbol: 'mGOOGL',
    address: {
      56: '0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  mnflx: {
    symbol: 'mNFLX',
    address: {
      56: '0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  mtsla: {
    symbol: 'mTSLA',
    address: {
      56: '0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  ltc: {
    symbol: 'LTC',
    address: {
      56: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://litecoin.org/',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.centre.io/usdc',
  },
  dai: {
    symbol: 'DAI',
    address: {
      56: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.makerdao.com/',
  },
  ada: {
    symbol: 'ADA',
    address: {
      56: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://www.cardano.org/',
  },
  band: {
    symbol: 'BAND',
    address: {
      56: '0xAD6cAEb32CD2c308980a548bD0Bc5AA4306c6c18',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bandprotocol.com/',
  },
  dot: {
    symbol: 'DOT',
    address: {
      56: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://polkadot.network/',
  },
  eos: {
    symbol: 'EOS',
    address: {
      56: '0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://eos.io/',
  },
  link: {
    symbol: 'LINK',
    address: {
      56: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://chain.link/',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      56: '0x55d398326f99059fF775485246999027B3197955',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://tether.to/',
  },
  btcb: {
    symbol: 'BTCB',
    address: {
      56: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bitcoin.org/',
  },
  xrp: {
    symbol: 'XRP',
    address: {
      56: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://ripple.com/xrp/',
  },
  atom: {
    symbol: 'ATOM',
    address: {
      56: '0x0Eb3a705fc54725037CC9e008bDede697f62F335',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://cosmos.network/',
  },
  yfii: {
    symbol: 'YFII',
    address: {
      56: '0x7F70642d88cf1C4a3a7abb072B53B929b653edA5',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://dfi.money/#/',
  },
  xtz: {
    symbol: 'XTZ',
    address: {
      56: '0x16939ef78684453bfDFb47825F8a5F714f12623a',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://www.tezos.com/',
  },
  bch: {
    symbol: 'BCH',
    address: {
      56: '0x8fF795a6F4D97E7887C79beA79aba5cc76444aDf',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://bch.info/',
  },
  yfi: {
    symbol: 'YFI',
    address: {
      56: '0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://yearn.finance/',
  },
  uni: {
    symbol: 'UNI',
    address: {
      56: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://uniswap.org/',
  },
  fil: {
    symbol: 'FIL',
    address: {
      56: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://filecoin.io/',
  },
  bake: {
    symbol: 'BAKE',
    address: {
      56: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.bakeryswap.org/',
  },
  burger: {
    symbol: 'BURGER',
    address: {
      56: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
      97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
    },
    decimals: 18,
    projectLink: 'https://burgerswap.org/',
  },
  bdigg: {
    symbol: 'bDIGG',
    address: {
      56: '0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://badger.finance/',
  },
  bbadger: {
    symbol: 'bBadger',
    address: {
      56: '0x1F7216fdB338247512Ec99715587bb97BBf96eae',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://badger.finance/',
  },
  trade: {
    symbol: 'TRADE',
    address: {
      56: '0x7af173F350D916358AF3e218Bdf2178494Beb748',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://unitrade.app/',
  },
  pnt: {
    symbol: 'PNT',
    address: {
      56: '0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ptokens.io/',
  },
  mir: {
    symbol: 'MIR',
    address: {
      56: '0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://mirror.finance/',
  },
  pbtc: {
    symbol: 'pBTC',
    address: {
      56: '0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ptokens.io/',
  },
  lto: {
    symbol: 'LTO',
    address: {
      56: '0x857B222Fc79e1cBBf8Ca5f78CB133d1b7CF34BBd',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://ltonetwork.com/',
  },
  pcws: {
    symbol: 'pCWS',
    address: {
      56: '0xbcf39F0EDDa668C58371E519AF37CA705f2bFcbd',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://game.seascape.network/',
  },
  zil: {
    symbol: 'ZIL',
    address: {
      56: '0xb86AbCb37C3A4B64f74f59301AFF131a1BEcC787',
      97: '',
    },
    decimals: 12,
    projectLink: 'https://www.zilliqa.com/',
  },
  lien: {
    symbol: 'LIEN',
    address: {
      56: '0x5d684ADaf3FcFe9CFb5ceDe3abf02F0Cdd1012E3',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://lien.finance/',
  },
  swth: {
    symbol: 'SWTH',
    address: {
      56: '0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
      97: '',
    },
    decimals: 8,
    projectLink: 'https://switcheo.network/',
  },
  dft: {
    symbol: 'DFT',
    address: {
      56: '0x42712dF5009c20fee340B245b510c0395896cF6e',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://www.dfuture.com/home',
  },
  gum: {
    symbol: 'GUM',
    address: {
      56: '0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://gourmetgalaxy.io/',
  },
  dego: {
    symbol: 'DEGO',
    address: {
      56: '0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://bsc.dego.finance/home',
  },
  nrv: {
    symbol: 'NRV',
    address: {
      56: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://nerve.fi/',
  },
  easy: {
    symbol: 'EASY',
    address: {
      56: '0x7C17c8bED8d14bAccE824D020f994F4880D6Ab3B',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://easyfi.network/',
  },
  oddz: {
    symbol: 'ODDZ',
    address: {
      56: '0xCD40F2670CF58720b694968698A5514e924F742d',
      97: '',
    },
    decimals: 18,
    projectLink: 'https://oddz.fi/',
  },
}

export default tokens

import React from 'react'
import { useNetworkChainId } from 'state/hooks'
import styled from 'styled-components'
import NlistItem from './NlistItem'

const ItemWrap = styled.div`
  position: absolute;
  top: 70px;
  color: ${({ theme }) => theme.colors.text};
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0px 0px 12px #a7a7a7;
  border-radius: 8px;
  padding: 20px;
  min-width: 300px;
  @media (min-width: 481px) and (max-width: 767px) {
    left: -40px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    left: -40px;
  }
`

const MenuItems = styled.div`
  margin: 0px 15px;
  cursor: pointer;
`
const MenuTitle = styled.div`
  font-family: 'GilroyHome';
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: 20px;
`

const Ndropdown = ({ updateMenuItem, itemData }) => {
  const selectItem = (index) => updateMenuItem(index)
  const chainId = useNetworkChainId()

  return (
    <ItemWrap>
      <MenuItems>
        <MenuTitle>Switch Network</MenuTitle>
        {itemData.map((item) => {
          const status = item.chainId === chainId
          const subtitle = status ? 'Connected' : 'Disconnected'

          return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div key={item.chainId} onClick={() => selectItem(item.chainId)}>
              <NlistItem
                iconimg={item.iconimg}
                title={item.title}
                subtitle={subtitle}
                status={status}
                isActive={status}
              />
            </div>
          )
        })}
      </MenuItems>
    </ItemWrap>
  )
}

export default React.memo(Ndropdown)

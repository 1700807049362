import React from 'react'
import { Button } from '@arborswap/uikit'
import { AutoRow } from 'components/Layout/Row'

export default function SwapModalFooter({ onConfirm }: { onConfirm: () => void }) {
  return (
    <>
      <AutoRow>
        <Button variant="primary" onClick={onConfirm} mt="12px" id="confirm-swap-or-send" width="100%">
          Confirm Bridge
        </Button>
      </AutoRow>
    </>
  )
}

import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNetworkChainId } from 'state/hooks'
import useSwitchNetwork from 'hooks/useSelectNetwork'
import Ndropdown from './Ndropdown'
import { CHAIN_MENU_ITEM } from './MenuItem'

const ItemWrap = styled.div`
  position: relative;
`

const MenuItem = styled.div`
  margin: 0px 15px;
  background: ${({ theme }) => theme.colors.background};
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  box-shadow: 0px 0px 4px ${({ theme }) => theme.colors.primary};
  @media (min-width: 481px) and (max-width: 767px) {
    width: 80px;
    height: 48px;
    display: flex;
    align-items: center;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 80px;
    height: 48px;
    display: flex;
    align-items: center;
  }
`
const MenuItemLeft = styled.div`
  display: inline-block;
  padding: 10px;
  @media (min-width: 481px) and (max-width: 767px) {
    width: 41px;
    float: left;
    padding: 0px;
    margin-left: 10px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    width: 41px;
    float: left;
    padding: 0px;
    margin-left: 8px;
  }
`
const MenuItemRight = styled.div`
  display: inline-block;
  background: ${({ theme }) => theme.colors.background};
  border-radius: 6px;
  @media (min-width: 481px) and (max-width: 767px) {
    padding: 0px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    padding: 0px;
  }
`
const ItemIcon = styled.span`
  display: inline-block;
  padding-right: 10px;
`
const ItemText = styled.span`
  font-family: 'GilroyHome';
  display: inline-block;
  vertical-align: top;
  padding: 5px 10px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.02em;
  @media (min-width: 481px) and (max-width: 767px) {
    display: none;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
`

const MenuButton = () => {
  const [toggle, setToggle] = useState(false)
  const [activeItem, setActiveItem] = useState({
    iconimg: '/images/headericons/BinanceSmartChainBadge.svg',
    title: 'Binance Smart Chain',
  })

  const userChainId = useNetworkChainId()

  const { switchNetwork } = useSwitchNetwork()

  const [itemData, setItemData] = useState(CHAIN_MENU_ITEM)

  const toggleNetworkDropdown = () => {
    if (toggle) {
      setToggle(false)
    } else {
      setToggle(true)
    }
  }

  useEffect(() => {
    if (userChainId) {
      const index = itemData.findIndex((item) => item.chainId === userChainId)
      setActiveItem({
        iconimg: itemData[index].iconimg,
        title: itemData[index].title,
      })
    }
  }, [userChainId, itemData])

  const updateMenuItem = (newChainId: number) => {
    // find index of newChainId
    const index = itemData.findIndex((item) => item.chainId === newChainId)

    switchNetwork(newChainId)

    // setActiveItem({
    //   iconimg: itemData[index].iconimg,
    //   title: itemData[index].title,
    // })

    setToggle(false)
  }

  return (
    <ItemWrap>
      <MenuItem onClick={toggleNetworkDropdown}>
        <MenuItemLeft>
          <ItemIcon>
            <img width="26" src={activeItem.iconimg} alt="" />
          </ItemIcon>
          <ItemText> {activeItem.title} </ItemText>
        </MenuItemLeft>
        <MenuItemRight>
          <img style={!toggle ? { transform: 'rotate(180deg)' } : {}} src="/images/headericons/arrow-up.svg" alt="" />
        </MenuItemRight>
      </MenuItem>
      {toggle ? <Ndropdown updateMenuItem={updateMenuItem} itemData={itemData} /> : ''}
    </ItemWrap>
  )
}

export default MenuButton

import React from 'react'
import { Link } from 'react-router-dom'
import { Pool } from 'state/types'
import styled from 'styled-components'
import NameCell from './Cells/NameCell'
import EndsInCell from './Cells/EndsInCell'

interface PoolRowProps {
  pool: Pool
  account?: string
  userDataLoaded?: boolean
}

const StyledRow = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 6px;
  width: 100%;
  display: flex;
  cursor: pointer;
`
const StyledGrid = styled.div`
  width: 100%;
  cursor: pointer;
`
const Stake = styled.div`
  padding: 24px 8px;
`
const StakeTitle = styled.span`
  font-family: 'GilroyHome';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #00783f;
  display: inline-block;
  float: left;
  padding-top: 5px;
  padding-right: 10px;
`
const StakeIcon = styled.img``

const PoolRow: React.FC<PoolRowProps> = ({ pool }) => {

  console.log(pool)
  return (
    <Link to="pools">
      <StyledGrid>
        <StyledRow role="row">
          <NameCell pool={pool} chainId={pool.chainId}/>
          <EndsInCell pool={pool} />
          <Stake>
            <StakeTitle>Stake</StakeTitle>
            <StakeIcon src="images/home/imgnew/poolicons/arrow-left.png" alt="" />
          </Stake>
        </StyledRow>
      </StyledGrid>
    </Link>
  )
}

export default PoolRow

import poolsConfig from 'config/constants/pools'
import normalStakeABI from 'config/abi/normalStake.json'
import lockStakeABI from 'config/abi/lockStake.json'
import customRewardABI from 'config/abi/newReward.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import getProvider from 'utils/getProvider'
import { PoolCategory } from 'config/constants/types'

// RBA pools use the native RBA token (wrapping ? unwrapping is done at the contract level)

const nonBnbPools = poolsConfig.filter((p) => p.poolCategory !== PoolCategory.NATIVECOIN)
const bnbPools = poolsConfig.filter((p) => p.poolCategory === PoolCategory.NATIVECOIN)

const lockPools = poolsConfig.filter((p) => p.isLock === true)
const normalPools = poolsConfig.filter((p) => p.isLock !== true)
const lockPoolsWithoutCustom = poolsConfig.filter((p) => p.isLock === true && p.isCustomReward !== true)
const normalPoolsWithoutCustom = poolsConfig.filter((p) => p.isLock !== true && p.isCustomReward !== true)
const membershipPools = poolsConfig.filter((p) => p.isMembership === true)
const nonMembershipPools = poolsConfig.filter((p) => p.isMembership === false)
const customRewardPools = poolsConfig.filter((p) => p.isCustomReward === true)

export const fetchPoolsAllowance = async (chainId: number, account) => {
  const chainPool = poolsConfig.filter((p) => p.chainId === chainId)
  const calls = chainPool.map((p) => ({
    address: getAddress(p.chainId, p.stakingToken.address),
    name: 'allowance',
    params: [account, getAddress(p.chainId, p.depositAddress)],
  }))

  const allowances = await multicall(chainId, erc20ABI, calls)
  return chainPool.reduce(
    (acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(allowances[index]).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (chainId: number, account) => {
  const provider = getProvider(chainId)
  // Non RBA pools
  const calls = nonBnbPools
    .filter((p) => p.chainId === chainId)
    .map((p) => ({
      address: getAddress(p.chainId, p.stakingToken.address),
      name: 'balanceOf',
      params: [account],
    }))
  const tokenBalancesRaw = await multicall(chainId, erc20ABI, calls)
  const tokenBalances = nonBnbPools
    .filter((p) => p.chainId === chainId)
    .reduce((acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }), {})

  // RBA pools
  const bnbBalance = await provider.getBalance(account)
  const bnbBalances = bnbPools.reduce(
    (acc, pool) => ({ ...acc, [pool.sousId]: new BigNumber(bnbBalance.toString()).toJSON() }),
    {},
  )

  return { ...tokenBalances, ...bnbBalances }
}

export const fetchUserNFT = async (chainId: number, account) => {
  // Non RBA pools
  const calls = membershipPools
    .filter((p) => p.chainId === chainId)
    .map((p) => ({
      address: getAddress(p.chainId, p.membershipAddress),
      name: 'balanceOf',
      params: [account],
    }))
  const nftBalancesRaw = await multicall(chainId, erc20ABI, calls)
  const nftBalances = membershipPools
    .filter((p) => p.chainId === chainId)
    .reduce((acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(nftBalancesRaw[index]).toJSON() }), {})

  const nonNftBalances = nonMembershipPools.reduce(
    (acc, pool) => ({ ...acc, [pool.sousId]: new BigNumber(0).toJSON() }),
    {},
  )

  return { ...nftBalances, ...nonNftBalances }
}

export const fetchUserStakeBalances = async (chainId: number, account) => {
  const normalCalls = normalPools
    .filter((p) => p.chainId === chainId)
    .map((p) => ({
      address: getAddress(p.chainId, p.contractAddress),
      name: 'stakingBalance',
      params: [account],
    }))

  const lockCalls = lockPools
    .filter((p) => p.chainId === chainId)
    .map((p) => ({
      address: getAddress(p.chainId, p.contractAddress),
      name: 'stakingBalance',
      params: [account],
    }))

  const userInfo = await multicall(chainId, normalStakeABI, normalCalls)
  const userLockInfo = await multicall(chainId, lockStakeABI, lockCalls)

  const lockStakedBalances = lockPools
    .filter((p) => p.chainId === chainId)
    .reduce(
      (acc, pool, index) => ({
        ...acc,
        [pool.sousId]: new BigNumber(userLockInfo[index]).toJSON(),
      }),
      {},
    )

  const stakedBalances = normalPools
    .filter((p) => p.chainId === chainId)
    .reduce(
      (acc, pool, index) => ({
        ...acc,
        [pool.sousId]: new BigNumber(userInfo[index]).toJSON(),
      }),
      {},
    )

  return { ...stakedBalances, ...lockStakedBalances }
}

export const fetchUserUnlockTimes = async (chainId: number, account) => {
  const normalCalls = normalPools
    .filter((p) => p.chainId === chainId)
    .map((p) => ({
      address: getAddress(p.chainId, p.contractAddress),
      name: 'startTime',
      params: [account],
    }))
  const lockCalls = lockPools
    .filter((p) => p.chainId === chainId)
    .map((p) => ({
      address: getAddress(p.chainId, p.contractAddress),
      name: 'userEndTime',
      params: [account],
    }))

  const userInfo = await multicall(chainId, normalStakeABI, normalCalls)
  const userLockInfo = await multicall(chainId, lockStakeABI, lockCalls)

  const lockStakedBalances = lockPools
    .filter((p) => p.chainId === chainId)
    .reduce(
      (acc, pool, index) => ({
        ...acc,
        [pool.sousId]: new BigNumber(userLockInfo[index]).toJSON(),
      }),
      {},
    )

  const stakedBalances = normalPools
    .filter((p) => p.chainId === chainId)
    .reduce(
      (acc, pool, index) => ({
        ...acc,
        [pool.sousId]: new BigNumber(userInfo[index]).toJSON(),
      }),
      {},
    )

  return { ...stakedBalances, ...lockStakedBalances }
}

export const fetchUserPendingRewards = async (chainId: number, account) => {
  const normalCalls = normalPoolsWithoutCustom
    .filter((p) => p.chainId === chainId)
    .map((p) => ({
      address: getAddress(p.chainId, p.contractAddress),
      name: 'getTotalRewards',
      params: [account],
    }))

  const lockCalls = lockPoolsWithoutCustom
    .filter((p) => p.chainId === chainId)
    .map((p) => ({
      address: getAddress(p.chainId, p.contractAddress),
      name: 'getTotalRewards',
      params: [account],
    }))

  const customCall = customRewardPools
    .filter((p) => p.chainId === chainId)
    .map((p) => ({
      address: getAddress(p.chainId, p.customRewardAddress),
      name: 'getTotalRewards',
      params: [account],
    }))

  const res = await multicall(chainId, normalStakeABI, normalCalls)

  const resCustom = await multicall(chainId, customRewardABI, customCall)

  const pendingRewards = normalPools
    .filter((p) => p.chainId === chainId)
    .reduce(
      (acc, pool, index) => ({
        ...acc,
        [pool.sousId]: new BigNumber(res[index]).toJSON(),
      }),
      {},
    )

  const pendingCustomRewards = customRewardPools
    .filter((p) => p.chainId === chainId)
    .reduce(
      (acc, pool, index) => ({
        ...acc,
        [pool.sousId]: new BigNumber(resCustom[index]).toJSON(),
      }),
      {},
    )

  const resLock = await multicall(chainId, lockStakeABI, lockCalls)

  const pendingLockRewards = lockPoolsWithoutCustom
    .filter((p) => p.chainId === chainId)
    .reduce(
      (acc, pool, index) => ({
        ...acc,
        [pool.sousId]: new BigNumber(resLock[index]).toJSON(),
      }),
      {},
    )
  return { ...pendingRewards, ...pendingLockRewards, ...pendingCustomRewards }
}

export const fetchUserWithdrawnRewards = async (chainId: number, account) => {
  const customCall = customRewardPools
    .filter((p) => p.chainId === chainId)
    .map((p) => ({
      address: getAddress(p.chainId, p.customRewardAddress),
      name: 'userWithdrawn',
      params: [account],
    }))

  const resCustom = await multicall(chainId, customRewardABI, customCall)

  const pendingCustomRewards = customRewardPools
    .filter((p) => p.chainId === chainId)
    .reduce(
      (acc, pool, index) => ({
        ...acc,
        [pool.sousId]: new BigNumber(resCustom[index]).toJSON(),
      }),
      {},
    )

  return { ...pendingCustomRewards }
}

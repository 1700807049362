import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { fetchUserNetwork } from 'state/network'
import { CHAIN_PARAMS } from 'config/constants/chains'
import { useNetworkChainId } from 'state/hooks'
import useActiveWeb3React from './useActiveWeb3React'

const useSwitchNetwork = () => {
  const { chainId, account, library } = useActiveWeb3React()
  const appChainId = useNetworkChainId()
  const dispatch = useDispatch()
  const switchNetwork = useCallback(
    (userChainId: number) => {
      if (account && userChainId !== chainId) {
        try {
          // library?.send('wallet_addEthereumChain', [CHAIN_PARAMS[userChainId], account])
          library?.send('wallet_switchEthereumChain', [{ chainId: CHAIN_PARAMS[userChainId].chainId }])
        } catch (error) {
          console.warn(error)
        }
      } else {
        dispatch(fetchUserNetwork(appChainId, account, userChainId))
      }
    },
    [chainId, account, library, dispatch, appChainId],
  )
  return { switchNetwork }
}

export default useSwitchNetwork

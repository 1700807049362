import { Currency, ETHER, Token } from '@arborswap/sdk'
import store from 'state'
import { getNativeName } from './getNativeName'
export function currencyId(currency: Currency): string {
  const state = store.getState()
  const { chainId } = state.network.data
  const nativeSymbol = getNativeName(chainId)
  if (currency === ETHER) return nativeSymbol
  if (currency instanceof Token) return currency.address
  throw new Error('invalid currency')
}

export default currencyId

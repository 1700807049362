import { useEffect, useState } from 'react'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'
import { ChainId } from '@arborswap/sdk'
import { useNetworkChainId } from 'state/hooks'
import poolsConfig from 'config/constants/pools'
export const parseAddress = (currAddress: Address, chainId: ChainId) => {
  return currAddress[chainId]
}

const useAddress = (curAddresses: Address) => {
  const chainId = useNetworkChainId()
  const [address, setAddress] = useState(parseAddress(curAddresses, chainId))
  useEffect(() => {
    setAddress(parseAddress(curAddresses, chainId))
  }, [chainId, curAddresses])
  return address
}

export const useMasterChefAddress = () => {
  return useAddress(addresses.masterChef)
}
export const useMulticallAddress = () => {
  return useAddress(addresses.multiCall)
}

export const usePortalAddress = () => {
  return useAddress(addresses.foreignPortal)
}

export const useDexManagementAddress = () => {
  return useAddress(addresses.dexManagement)
}

export const useNativeWrapCurrencyAddress = () => {
  return useAddress(addresses.nativeWrapped)
}

export const useRouterAddress = () => {
  return useAddress(addresses.routerAddress)
}

export const useDepositAddress = (id: number) => {
  const addr = poolsConfig.find((p) => p.sousId === id).depositAddress
  return useAddress(addr)
}

export const useRewardAddress = (id: number) => {
  const addr = poolsConfig.find((p) => p.sousId === id).customRewardAddress
  return useAddress(addr)
}

export const useSousChefAddress = (id: number) => {
  const addr = poolsConfig.find((p) => p.sousId === id).contractAddress
  return useAddress(addr)
}

import { useEffect } from 'react'
import { State } from 'state/types'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useSwitchNetwork from 'hooks/useSelectNetwork'
import { DEFAULT_CHAIN_ID, SUPPORTED_CHAINS } from 'config/constants/chains'
import { fetchUserNetwork } from './network'

export const useNetworkChainId = (): number => {
  const chainId = useSelector((state: State) => state.network.data.chainId)
  const storageChainId = parseInt(window.localStorage.getItem('chainIdStatus') || '')
  if (SUPPORTED_CHAINS.includes(chainId)) {
    return chainId
  }

  if (SUPPORTED_CHAINS.includes(storageChainId)) {
    return storageChainId
  }

  if (!chainId) return DEFAULT_CHAIN_ID
  return chainId
}

export const useUpdateNetwork = () => {
  const dispatch = useAppDispatch()
  const { chainId, account } = useActiveWeb3React()
  const appChainId = useNetworkChainId()
  const { switchNetwork } = useSwitchNetwork()
  useEffect(() => {
    if (account && !SUPPORTED_CHAINS.includes(chainId)) {
      switchNetwork(appChainId)
    }

    dispatch(fetchUserNetwork(chainId, account, appChainId))

    // Load initial vault state in update netowrk to stop mount re-render
  }, [chainId, account, appChainId, switchNetwork, dispatch])
}

import React from 'react'
import { Pool } from 'state/types'

interface AprCellProps {
  pool: Pool
}

const AutoAprCell: React.FC<AprCellProps> = ({ pool }) => {
  console.debug('AutoAprCell', pool)
  return <></>
}

export default AutoAprCell

import { Flex, FlexProps, Skeleton } from '@arborswap/uikit'
import BigNumber from 'bignumber.js'
import Balance from 'components/Balance'
import React from 'react'
import { Pool } from 'state/types'
import styled from 'styled-components'

const AprLabelContainer = styled(Flex)`
  &:hover {
    opacity: 0.5;
  }
`

interface AprProps extends FlexProps {
  pool: Pool
  stakedBalance?: BigNumber
  showIcon?: boolean
  performanceFee?: number
}

const Apr: React.FC<AprProps> = ({ pool, ...props }) => {
  const { isFinished, rate } = pool

  // console.log(`earningsPercentageToDisplay`, rate)
  return (
    <AprLabelContainer alignItems="center" justifyContent="space-between" {...props}>
      {rate || isFinished ? (
        <>
          <Balance
            // onClick={openRoiModal}
            fontSize="16px"
            fontWeight="700"
            color="#00783F"
            // isDisabled={isFinished}
            // isDisabled={isFinished}
            // value={isFinished ? 0 : rate}
            value={rate}
            decimals={2}
            unit="%"
          />
          {/* {!isFinished && showIcon && (
            <Button onClick={openRoiModal} variant="text" width="20px" height="20px" padding="0px" marginLeft="4px">
              <CalculateIcon color="textSubtle" width="20px" />
            </Button>
          )} */}
        </>
      ) : (
        <Skeleton width="80px" height="16px" />
      )}
    </AprLabelContainer>
  )
}

export default Apr

import { parseUnits } from '@ethersproject/units'
import { Currency, CurrencyAmount, ETHER, JSBI, Token, TokenAmount } from '@arborswap/sdk'
// import { ParsedQs } from 'qs'
import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useENS from 'hooks/ENS/useENS'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCurrency } from 'hooks/Tokens'
import { useTranslation } from 'contexts/Localization'
import useRelayPortal, { PortalRelayInfo } from 'hooks/useRelayPortal'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { getCurrencySymbol, getNativeSymbol } from 'utils/getNativeName'
import { isAddress } from 'utils'
import { AppDispatch, AppState } from '../index'
import { useCurrencyBalances } from '../wallet/hooks'
import { Field, selectCurrency, setGas, setRecipient, typeInput } from './actions'
import { PortalTx } from './reducer'
// import { useUserSlippageTolerance } from '../user/hooks'

export function usePortalState(): AppState['portal'] {
  return useSelector<AppState, AppState['portal']>((state) => state.portal)
}

export function usePortalActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void
  onUserInput: (field: Field, typedValue: string) => void
  onGasToggle: (value: boolean) => void
  onChangeRecipient: (recipient: string | null) => void
} {
  const { chainId } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency instanceof Token ? currency.address : currency === ETHER ? getNativeSymbol(chainId) : '',
        }),
      )
    },
    [dispatch, chainId],
  )

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch],
  )

  const onGasToggle = useCallback(
    (value: boolean) => {
      dispatch(setGas({ withGas: value }))
    },
    [dispatch],
  )

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }))
    },
    [dispatch],
  )

  return {
    onCurrencySelection,
    onUserInput,
    onGasToggle,
    onChangeRecipient,
  }
}

// try to parse a user entered amount for a given token
export function tryParseAmount(value?: string, currency?: Currency): CurrencyAmount | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

/**
 * Returns true if any of the pairs or tokens in a trade have the given checksummed address
 * @param trade to check for the given address
 * @param checksummedAddress address to check in the pairs and tokens
 */

// from the current swap inputs, compute the best trade and return it.
export function useDerivedPortalInfo(): {
  currencies: { [field in Field]?: Currency }
  currencyBalances: { [field in Field]?: CurrencyAmount }
  parsedAmount: CurrencyAmount | undefined
  inputError?: string
  portalInfo: PortalRelayInfo | undefined
  portalTx: PortalTx
} {
  const { account, chainId } = useActiveWeb3React()
  const { t } = useTranslation()

  const {
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    recipient,
    withGas,
  } = usePortalState()

  const inputCurrency = useCurrency(inputCurrencyId)
  const recipientLookup = useENS(recipient ?? undefined)

  const validRecipient = recipient === '' ? null : recipient
  const to: string | null = (validRecipient === null ? account : recipientLookup.address) ?? null

  const parsedAmount = tryParseAmount(typedValue, inputCurrency) ?? undefined
  const relevantTokenBalances = useCurrencyBalances(account ?? undefined, [inputCurrency ?? undefined])

  const inputToken = wrappedCurrency(inputCurrency, chainId)
  const portalInfo = useRelayPortal(
    inputToken,
    parsedAmount?.toSignificant(parsedAmount.currency.decimals),
    withGas ? '1' : '0',
  )

  const currencyBalances = {
    [Field.INPUT]: relevantTokenBalances[0],
    [Field.OUTPUT]: relevantTokenBalances[1],
  }

  const currencies: { [field in Field]?: Currency } = {
    [Field.INPUT]: inputCurrency ?? undefined,
  }

  let inputError: string | undefined
  if (!account) {
    inputError = t('Connect Wallet')
  }

  if (!parsedAmount) {
    inputError = inputError ?? t('Enter an amount')
  }

  if (!currencies[Field.INPUT]) {
    inputError = inputError ?? t('Select a token')
  }

  if (!portalInfo) {
    inputError = inputError ?? t('Waiting for price')
  }

  const formattedTo = isAddress(to)
  if (!to || !formattedTo) {
    inputError = inputError ?? t('Enter a recipient')
  }
  // compare input balance to max input based on version
  const [balanceIn, amountIn] = [currencyBalances[Field.INPUT], parsedAmount]

  if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
    inputError = t('Insufficient %symbol% balance', { symbol: getCurrencySymbol(amountIn.currency, chainId) })
  }

  const portalTx: PortalTx = useMemo(() => {
    return {
      tokenIn: inputToken,
      addressTo: to ?? '',
      ammount: parsedAmount,
      withGas,
    }
  }, [inputToken, to, parsedAmount, withGas])

  return {
    currencies,
    currencyBalances,
    parsedAmount,
    inputError,
    portalInfo,
    portalTx,
  }
}

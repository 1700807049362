import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import { PortalTx } from 'state/portal/reducer'
import { SerializedBigNumber } from 'state/types'

export const BIG_ZERO = new BigNumber(0)
export const BIG_ONE = new BigNumber(1)
export const BIG_NINE = new BigNumber(9)
export const BIG_TEN = new BigNumber(10)

export const ethersToSerializedBigNumber = (ethersBn: ethers.BigNumber): SerializedBigNumber =>
  ethersToBigNumber(ethersBn).toJSON()

export const ethersToBigNumber = (ethersBn: ethers.BigNumber): BigNumber => new BigNumber(ethersBn.toString())

export const formatPortalValue = (portalTx: PortalTx): string => {
  if (!portalTx.ammount) return '0'
  if (!portalTx.tokenIn.decimals) return '0'
  return ethers.utils.parseUnits(portalTx.ammount.toFixed(), portalTx.tokenIn.decimals).toString()
}

export const formatInputToStringValue = (amount: string, decimal: number): string => {
  if (!amount) return '0'
  if (!decimal) return '0'
  return ethers.utils.parseUnits(amount, decimal).toString()
}

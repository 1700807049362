// import { ChainId } from '@arborswap/sdk'
import { BigNumber } from 'ethers'
import store from 'state'
// import { GAS_PRICE_GWEI } from 'state/user/hooks/helpers'
import getProvider from './getProvider'

/**
 * Function to return gasPrice outwith a react component
 */
const getGasPrice = async (): Promise<string> => {
  const state = store.getState()
  const { chainId } = state.network.data
  const provider = getProvider(chainId)
  const gasPrice = (await provider.getGasPrice()) as BigNumber
  const userGas = state.user.gasPrice || gasPrice.toString()
  // console.log('userGas', userGas)
  return userGas
}

export default getGasPrice

import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useNetworkChainId } from 'state/hooks'
import useInterval from 'hooks/useInterval'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import getProvider from 'utils/getProvider'
import { setBlock } from '.'
import { State } from '../types'

export const usePollBlockNumber = (refreshTime = 50000) => {
  const dispatch = useAppDispatch()
  const isWindowVisible = useIsWindowVisible()
  const chainId = useNetworkChainId()
  const library = getProvider(chainId)
  // const { library } = useActiveWeb3React()
  // console.log('usePollBlockNumber', chainId, library)

  useInterval(
    () => {
      const fetchBlock = async () => {
        try {
          const blockNumber = (await library.getBlockNumber()) as unknown as number
          dispatch(setBlock({ chainId, blockNumber }))
        } catch {
          console.error('Could not fetch block number')
        }
      }

      fetchBlock()
    },
    refreshTime,
    isWindowVisible,
  )
}

export const useBlock = () => {
  const chainId = useNetworkChainId()

  return useSelector((state: State) => state.block.blockNumber[chainId ?? -1])
  // return useSelector((state: State) => state.block)
}

// export const useInitialBlock = () => {
//   return useSelector((state: State) => state.block.initialBlock)
// }

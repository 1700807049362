import React, { useCallback } from 'react'
import { InjectedModalProps } from '@arborswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { PortalTx } from 'state/portal/reducer'
import { PortalRelayInfo } from 'hooks/useRelayPortal'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent,
} from 'components/TransactionConfirmationModal'
import SwapModalFooter from './SwapModalFooter'
import SwapModalHeader from './SwapModalHeader'

interface ConfirmSwapModalProps {
  portalTx?: PortalTx
  infoTx?: PortalRelayInfo
  attemptingTxn: boolean
  txHash?: string
  recipient: string | null
  onConfirm: () => void
  swapErrorMessage?: string
  customOnDismiss?: () => void
}

const ConfirmSwapModal: React.FC<InjectedModalProps & ConfirmSwapModalProps> = ({
  portalTx,
  infoTx,
  onConfirm,
  onDismiss,
  customOnDismiss,
  recipient,
  attemptingTxn,
  swapErrorMessage,
  txHash,
}) => {
  const { t } = useTranslation()

  const modalHeader = useCallback(() => {
    return portalTx ? <SwapModalHeader portalTx={portalTx} portalInfo={infoTx} recipient={recipient} /> : null
  }, [portalTx, infoTx, recipient])

  const modalBottom = useCallback(() => {
    return portalTx ? <SwapModalFooter onConfirm={onConfirm} /> : null
  }, [portalTx, onConfirm])

  // text to show while loading
  const pendingText = t('Bridging %amountA% %symbolA% for %amountB% %symbolB%', {
    amountA: portalTx?.ammount?.toSignificant(6) ?? '',
    symbolA: portalTx?.tokenIn?.symbol ?? '',
    amountB: infoTx?.receiveAmount?.toSignificant(6) ?? '',
    symbolB: portalTx?.tokenIn?.symbol ?? '',
  })

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={swapErrorMessage} />
      ) : (
        <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />
      ),
    [onDismiss, modalBottom, modalHeader, swapErrorMessage],
  )

  return (
    <TransactionConfirmationModal
      title={t('Confirm Bridge')}
      onDismiss={onDismiss}
      customOnDismiss={customOnDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
      currencyToAdd={portalTx?.tokenIn}
    />
  )
}

export default ConfirmSwapModal
